import { Divider, Paper } from '@mui/material';
import {
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  TextField,
  TopToolbar,
  useRecordContext,
  useRefresh
} from 'react-admin';

import CurrencyField from '../../../components/CurrencyField';
import RequireUserCountry from '../../../components/RequireUserCountry';
import { CreditResourceDetails } from '../../../types';
import BreakdownItemWrapper from '../components/BreakdownItemField';
import RemoveFeeButton from '../components/RemoveFeeButton';
import { transformPaybacks } from '../utils/paybacks';
import CreateTransaction from './components/CreateTransaction';
import RegisterManualPayment from './components/RegisterManualPayment';
import TransactionTypeField from './components/TransactionTypeField';

const Transactions = () => {
  const refresh = useRefresh();
  const record = useRecordContext<CreditResourceDetails>();

  if (!record?.plan?.transactions) {
    return null;
  }

  const transformedTransactions = transformPaybacks(record.plan.transactions);

  return (
    <>
      <TopToolbar sx={{ alignItems: 'center', paddingRight: '8px !important', gap: '16px' }}>
        <RequireUserCountry record={record.user} countries={['SE']}>
          <RegisterManualPayment />
          <Divider sx={{ height: 22, m: 0.5 }} orientation="vertical" />
          <CreateTransaction agreementId={record?.agreement.id} onChange={refresh} />
        </RequireUserCountry>
      </TopToolbar>

      <Paper>
        <Datagrid
          data={transformedTransactions}
          total={transformedTransactions.length}
          isLoading={false}
          sort={{ field: 'dueDate', order: 'ASC' }}
          bulkActionButtons={false}
          aria-label="transaction table"
          sx={{
            // If the row has a button, make nested table rows taller
            // This accounts for the remove fee button in the nested table
            '& .MuiTableRow-root:has(.MuiButton-root) .MuiTableBody-root .MuiTableRow-root': {
              height: '40px'
            }
          }}
        >
          <FunctionField
            source="dueDate"
            render={(record: typeof transformedTransactions[number]) => (
              <DateField
                source="dueDate"
                record={{ dueDate: record.dueDate.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3') }}
              />
            )}
            textAlign="right"
          />
          <NumberField source="paybackId" label="Invoice" />
          <TransactionTypeField label="Type" />
          <CurrencyField
            source="amount"
            record={{ country: record.agreement.country, ...record }}
            textAlign="right"
          />
          <TextField source="status" />
          <TextField source="account" />
          <DateField source="transactionDate" showTime textAlign="right" />

          <BreakdownItemWrapper label="Due" textAlign="right">
            <CurrencyField source="amount" record={{ country: record.agreement.country }} textAlign="right" />
          </BreakdownItemWrapper>
          <BreakdownItemWrapper label="Paid" textAlign="right">
            <CurrencyField
              source="amountPaid"
              record={{ country: record.agreement.country }}
              textAlign="right"
            />
          </BreakdownItemWrapper>
          <BreakdownItemWrapper label="Invoice Item">
            <TextField source="type" />
            <RemoveFeeButton agreementId={String(record.id)} />
          </BreakdownItemWrapper>
        </Datagrid>
      </Paper>
    </>
  );
};

export default Transactions;
